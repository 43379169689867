import { createAction, props } from '@ngrx/store';
import { Appointment } from '@models/appointments.interface';

export const getAppointmentsAction = createAction('[Appointments] Get Appointments');

export const getAppointmentsSuccess = createAction(
  '[Appointments] Get Appointments Success',
  props<{ appointments: Appointment[] }>()
);

export const getAppointmentsFailure = createAction('[Appointments] Get Appointments Failure');

export const setSelectedAppointment = createAction('[Appointments] Set Selected Appointments', props<{ appointment: Appointment }>());

export const removeSelectedAppointment = createAction('[Appointments] Remove Selected Appointment');

export const updateSelectedAppointment = createAction('[Appointments] Update Appointment', props<{ appointment: Appointment }>());
